@font-face {
  font-family: "Avenir LT Pro";
  src: url("../fonts/AvenirLTPro-Medium.eot");
  src: url("../fonts/AvenirLTPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirLTPro-Medium.woff2") format("woff2"),
    url("../fonts/AvenirLTPro-Medium.woff") format("woff"),
    url("../fonts/AvenirLTPro-Medium.ttf") format("truetype"),
    url("../fonts/AvenirLTPro-Medium.svg#AvenirLTPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir LT Pro";
  src: url("../fonts/AvenirLTPro-Light.eot");
  src: url("../fonts/AvenirLTPro-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirLTPro-Light.woff2") format("woff2"),
    url("../fonts/AvenirLTPro-Light.woff") format("woff"),
    url("../fonts/AvenirLTPro-Light.ttf") format("truetype"),
    url("../fonts/AvenirLTPro-Light.svg#AvenirLTPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir LT Pro";
  src: url("../fonts/AvenirLTPro-Heavy.eot");
  src: url("../fonts/AvenirLTPro-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirLTPro-Heavy.woff2") format("woff2"),
    url("../fonts/AvenirLTPro-Heavy.woff") format("woff"),
    url("../fonts/AvenirLTPro-Heavy.ttf") format("truetype"),
    url("../fonts/AvenirLTPro-Heavy.svg#AvenirLTPro-Heavy") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir LT Pro";
  src: url("../fonts/AvenirLTPro-Book.eot");
  src: url("../fonts/AvenirLTPro-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirLTPro-Book.woff2") format("woff2"),
    url("../fonts/AvenirLTPro-Book.woff") format("woff"),
    url("../fonts/AvenirLTPro-Book.ttf") format("truetype"),
    url("../fonts/AvenirLTPro-Book.svg#AvenirLTPro-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir LT Pro";
  src: url("../fonts/AvenirLTPro-Black.eot");
  src: url("../fonts/AvenirLTPro-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AvenirLTPro-Black.woff2") format("woff2"),
    url("../fonts/AvenirLTPro-Black.woff") format("woff"),
    url("../fonts/AvenirLTPro-Black.ttf") format("truetype"),
    url("../fonts/AvenirLTPro-Black.svg#AvenirLTPro-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@mixin main-heading{
  font-size: 1.25rem;
  font-weight: 800;
  margin: 0;
}

@mixin secondary-heading{
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
  padding-bottom: 15px;
}
@mixin general-button{
  background-color: $pink !important;
  border-radius: 0.5rem !important;
  color: $white !important;
  font-size: 0.875rem !important;
  text-transform: none !important;
  box-shadow: none !important;
  padding: 6px 25px !important;  
}

@mixin bottom-menu-item{
  .menu-item{
    text-align: center !important;
    a{
      color: $white;
      border-radius: 0.25rem;      
      display: block;
      padding: 0.688rem 0;                
    }
    a:hover,
    a.active{      
      color: $white;
    }
  }
}