$mainbg: #F0FBFF;
$text: #000000;
$menu: #6d6d6d;
$menuhoverbg: #EDE7F6;
$pink: #E84F6B;
$green: #007A7A;
$purple: #45338C;
$yellow: #FFB205;
$skyblue: #53B5E0;
$orange: #FF5939;
$lightgrey: #F2F2F2;
$white: #ffffff;
$border: #E2E2E2;
$lightblue: #F8FDFF;
$greybox: #F9F9F9;

